:root {
    --app-bar-height: 64px;
}

#link-for-logo {
    width: 86px;
}

.logo {
    width: 40px;
    cursor: pointer;
}

.github-logo {
    width: 24px;
    cursor: pointer;
    display: block;
}

#app-bar {
    z-index: 2;
    display: flex;
    height: var(--app-bar-height);
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    background-color: var(--color-white);
    /*
      Hide the app bar on scroll.
      Stacking context: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context
      */
    position: fixed;
    transition: top 0.3s;
    top: 0;
    width: 100%;
    max-width: var(--max-app-width);
    padding: 0 var(--default-page-padding);
}

nav {
    display: flex;
    height: 100%;
    justify-content: space-between;
    font-size: 0.9em;
    margin: 0 5px;
}

nav > * {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 10px;
    cursor: pointer;
    color: var(--color-gray-font);
    margin: 0 1px;
    border-bottom: 0 solid white;
}

nav > *:hover {
    border-bottom: 3px solid var(--color-yellow);
    padding-top: 3px;
    color: var(--color-gray-font);
    opacity: 1;
    transition: border-bottom-color .2s;
}

#designs-container:hover {
    cursor: pointer;
}

#dark-theme-and-github-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    min-width: 86px;
}

#designs-dropdown-container {
    position: absolute;
    /*Set left property using myWindowSizeListener()*/
    top: 69px;
    margin-top: -3px;
    opacity: 0;
    visibility: hidden;
}

#code-dropdown-container {
    position: absolute;
    /*Set left property using myWindowSizeListener()*/
    top: 69px;
    margin-top: -3px;
    opacity: 0;
    visibility: hidden;
}

.center-the-dropdown {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.triangle {
    width: 60px;
    height: 24px;
    position: relative;
    overflow: hidden;
    top: -10px;
    z-index: 1;
}

/* Create shadow behind the triangle:
   https://gist.github.com/bohmanart/1686007*/
.triangle:after {
    content: "";
    position: absolute;
    width: 18px;
    height: 18px;
    transform: rotate(45deg);
    top: 16px;
    left: 21px;
    background-color: var(--color-triangle-bg);
    border: 1px solid var(--color-triangle-border);
    -webkit-box-shadow: var(--dropdown-triangle-box-shadow);
    box-shadow: var(--dropdown-triangle-box-shadow);
}

.dropdown {
    display: flex;
    flex-direction: column;
    z-index: 0;
    width: 340px;
    margin-top: -10px;
    padding: 14px 0;
    /* background-color: var(--color-white); */
    background-color: var(--color-dropdown-background);
    border-radius: var(--border-radius);
    -webkit-box-shadow: var(--dropdown-box-shadow);
    box-shadow: var(--dropdown-box-shadow);
}

.dropdown > a {
    height: 50px;
    padding: 0 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dropdown > a:hover {
    background-color: var(--color-gray-hover-bckround);
    transition: background-color .1s ease-out;
}

.dropdown > a p {
    display: flex;
    align-self: center;
    font-family: var(--default-font);
    font-size: 1em;
    color: var(--color-gray-lighter);
}

.app-logos-container {
    display: flex;
    align-self: center;
}

.app-logos-container > img {
    padding-left: 5px;
    width: 34px;
    height: 34px;
}

.code-dropdown-width {
    width: 290px;
}


/*Selected states*/
.nav-selected {
    border-bottom: 3px solid var(--color-gray-lighter) !important;
    padding-top: 3px;
    color: var(--color-gray-lighter) !important;
    margin: 0 1px;
}

/*Add this to an dropdown element using JS */
/*Also swap the icons for darker icons*/
.dropdown-selected {
    background-color: var(--color-dropdown-selected);
}

.dropdown-selected p {
    color: var(--color-dropdown-selected-text) !important;
}

/*Dark Theme button styling*/
.switch {
    position: relative;
    /*display: inline-block;*/
    display: block;
    width: 42px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .2s;
    transition: .2s;
    border-radius: 100px;
}

.slider:before {
    position: absolute;
    content: url("./../vector-images/moon-icon.svg");
    height: 20px;
    width: 20px;
    left: 0;
    bottom: -2px;
    background-color: var(--color-gray-darkest);
    -webkit-transition: .2s;
    transition: .2s;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 2px 0 3px;
    border-radius: 100px;
}

input:checked + .slider {
    background-color: var(--color-yellow-faded);
}

input:checked + .slider:before {
    -webkit-transform: translateX(17px);
    -ms-transform: translateX(17px);
    transform: translateX(17px);
    content: url("./../vector-images/moon-icon-dark.svg");
    background-color: var(--color-yellow);
}

/*-----------------*/

@media only screen and (max-width: 600px) {
    #app-bar {
        padding: 0 var(--small-page-padding);
    }
}

@media only screen and (max-width: 500px) {
    #link-for-logo {
        width: auto;
    }

}

@media only screen and (max-width: 460px) {
    #github-logo-on-nav-bar {
        display: none;
    }
    #dark-theme-and-github-container {
        min-width: auto;
    }
    nav > * {
       padding: 0 5px;
    }
}
