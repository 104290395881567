#project-head {
    padding: 120px var(--default-page-padding) 0 var(--default-page-padding);
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
}

#project-head h1 {
    padding: 0 0 12px 0;
}

#header-description {
    font-size: 0.9em;
    color: var(--color-gray-font);
}

#project-head img {
    width: 100%;
    border-radius:var(--border-radius);
    display: block;
    -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
    margin: 54px 0 0 0;
}

.try-app-button {
    background-color: var(--color-yellow);
    border: none;
    border-radius: 100px;
    padding: 1px 15px 0px 15px;
    font-family: var(--bold-font);
    font-size: 0.9em;
    color: var(--color-try-app-button-text);
    text-decoration: none;
    margin-top: 20px;
    height: 30px;
    display: flex;
    align-items: center;
}

.try-app-button:hover {
    cursor: pointer;
    background-color: var(--color-yellow-lighter);
    transition: background-color var(--hover-transition) ease-in-out;
}

.mg-top-10 {
    margin-top: 10px;
}



@media only screen and (max-width: 600px) {
    #project-head {
        padding: 120px var(--small-page-padding) 0 var(--small-page-padding);
    }

    #project-head h1 {
        font-size: 2.5em;
    }
}