.h3-padding {
    line-height: 1.2;
}

.design-cards-container {
    display: grid;
    /*Equal size columns: https://stackoverflow.com/a/61240964/10029397*/
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 25px;
    max-width: 1152px;
    margin: 0 auto;
    padding: 0 var(--default-page-padding) 50px var(--default-page-padding);
}

/*CSS Grid*/
.container-one {
    grid-column: 1/2;
    grid-row: 1/2;
}

.container-two {
    grid-column: 2/3;
    grid-row: 1/3;
}

.container-three {
    grid-column: 1/2;
    grid-row: 2/3;
}

.container-four {
    grid-column: 1/3;
    grid-row: auto;
}

/*Fonts*/

.secondary-font {
    font-family: var(--secondary-font);
    font-size: 0.82em;
    line-height: 1.6;
}

.secondary-font b {
    font-family: var(--secondary-font-bold);
    line-height: 1.6;
}

.secondary-font-bold {
    font-family: var(--secondary-font-bold);
    font-size: 0.82em;
    line-height: 1.6;
}

.secondary-font-link {
    font-family: var(--secondary-font);
    font-size: 0.82em;
    text-decoration: underline;
    color: var(--color-green);
    line-height: 1.6;
}

.secondary-font-link-no-font-size {
    font-family: var(--secondary-font);
    text-decoration: underline;
    color: var(--color-green);
    line-height: 1.6;
}

.dash-regular-weight {
    font-family: var(--secondary-font);
    padding-right: 10px;
}

.last-line {
    line-height: 0;
}

.h5-padding-bottom {
    padding-bottom: 20px;
}

.h5-padding-top {
    padding-top: 30px;
}

.vertical-title-container {
    border-radius: var(--border-radius);
    border: 1px solid var(--color-gray-accent);
    padding: 30px;
}

.vertical-title-container p {
    font-size: 0.82em;
}

.remove-padding-bottom {
    padding-bottom: 0;
}

@media only screen and (max-width: 750px) {
    .design-cards-container {
        display: grid;
        margin: 0 auto;
        padding: 0 var(--default-page-padding) 25px var(--default-page-padding);
    }

    .design-card {
        width: 100%;
        margin-right: 0;
        margin-bottom: 25px;
    }

    .design-cards-container div:last-of-type {
        margin-bottom: 0;
    }

    /*CSS Grid*/
    .container-one {
        grid-column: 1/3;
        grid-row: 1/2;
    }

    .container-two {
        grid-column: 1/3;
        grid-row: 2/3;
    }

    .container-three {
        grid-column: 1/3;
        grid-row: 3/4;
    }

    .design-cards-container section:last-of-type {
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 600px) {
    .design-cards-container {
        padding: 0 var(--small-page-padding) 25px var(--small-page-padding);
    }
}

@media only screen and (max-width: 500px) {
    .about-title {
        font-size: 2.5em;
    }
}

@media only screen and (max-width: 400px) {
    .responsive-list {
        display: flex;
        flex-direction: column;
    }
    .vertical-title-container {
        padding: 30px 20px;
    }
}