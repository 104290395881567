/* Styles applying to all project pages */
/* Style for one column component. */
.project-page-column-component-container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    max-width: 800px;
    padding: var(--distance-between-project-page-components) var(--default-page-padding) 0 var(--default-page-padding);
}

.project-page-column-component-container span {
    font-family: var(--secondary-font-bold);
}

/* Style for two column component. */
.project-page-row-component-container {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    max-width: 800px;
    padding: var(--distance-between-project-page-components) var(--default-page-padding) 0 var(--default-page-padding);
}

.gif {
    min-width: 229px;
    height: 408px;
    border: 1px solid var(--color-gray-accent);
}

.column-one {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-right: 7em;
}

.space-between-paragraphs {
    padding-bottom: 13px;
}

/* About project component. */
.platform-link {
    font-family: var(--secondary-font);
}

.platforms {
    display: flex;
    flex-direction: row;
}

.regular-font {
    font-family: var(--secondary-font) !important;
}

/* Bullet point design */
.bullet-point-container {
    display: flex;
    flex-direction: row;
}

.dash {
    padding-right: 10px;
}

.margin-top {
    margin-top: 10px;
}

/* If image is the last element of a component,
   add margin-bottom for visual design purposes. */
.image-bottom-margin {
    margin-bottom: 5px;
}

.responsive-image {
    width: 100%;
    /* https://css-tricks.com/using-css-cursors/ */
    cursor: url("../vector-images/zoom.svg"), pointer;
}

/*
   Adds height: auto only for Firefox browser.
   https://dev.to/krantikr/browser-specific-css-for-internet-explorer-firefox-chrome-safari-and-edge-394p
*/
@supports (-moz-appearance:none) {
    .responsive-image { height: auto; }
}

/* Styling for requirements section. It's not a
   reusable component. */
.requirements-columns-container {
    display: flex;
    flex-direction: row;
}

.col-one {
    flex-direction: column;
    width: 50%;
    margin-right: 40px;
}

.col-two {
    width: 50%;
}

.nested-dash {
    padding-left: 30px;
    padding-right: 10px;
}

/* Styling for any image/gif that opens as modal. */
.modal {
    display: none;
    position: fixed;
    z-index: 3;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.8);
}

/* Modal Content */
.modal-content {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    max-width: 100%;
    max-height: 100%;
}

/* The Close Button */
.close {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 25px;
    right: 25px;
    z-index: 1;
    width: 46px;
    height: 46px;
    box-shadow: none;
    border-radius: 1000px;
    transition: box-shadow .1s ease-in-out, background-color .1s ease-in-out;
}

.close div {
    width: 24px;
    height: 24px;
    background: url("../vector-images/close-icon.svg") no-repeat;
}

.close:hover {
    background-color: var(--color-yellow);
    border-radius: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 46px;
    height: 46px;
    -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    transition: box-shadow .1s ease-in-out;
}

.close:hover div {
    background: url("../vector-images/close-icon-dark.svg") no-repeat;
}

.cursor {
    cursor: pointer;
}

.project-page-gif {
    height: auto;
    width: 100%;
}


.added-padding-top {
    padding-top: 20px;
}

.more-code-projects-button {
    top: 197px;
}


/* More coding projects */
@media only screen and (min-width: 900px) {
    .more-coding-projects-img {
        width: 674px;
    }
}

/*-----------*/

@media only screen and (max-width: 700px) {
    .column-one {
        margin-right: 3em;
    }

}

@media only screen and (max-width: 600px) {
    .project-page-column-component-container {
        padding: var(--distance-between-project-page-components) var(--small-page-padding) 0 var(--small-page-padding);
    }

    .project-page-row-component-container {
        padding: var(--distance-between-project-page-components) var(--small-page-padding) 0 var(--small-page-padding);
    }

    .requirements-columns-container {
        display: flex;
        flex-direction: column;
    }

    .col-one {
        flex-direction: column;
        width: 100%;
        margin-right: 0;
    }

    .col-two {
        flex-direction: column;
        width: 100%;
    }
}

@media only screen and (max-width: 500px) {
    .project-page-row-component-container {
        display: flex;
        flex-direction: column;
    }

    .column-one {
        margin-right: 0;
    }

    .gif {
        max-width: 320px;
        height: auto;
        display: flex;
        align-self: center;
    }

    .column-text {
        padding-bottom: 13px;
    }
}