:root {
    --next-previous-button-size: 46px;
}

#design-projects-container {
    padding: 0 var(--default-page-padding);
    margin-bottom: var(--distance-between-home-page-components);
    position: relative;
}

.design-project-container-margin-top {
    margin-top: 100px;
}

#all-projects {
    display: flex;
    flex-direction: row;
    max-width: var(--max-app-width);
    overflow-y: hidden;
    overflow-x: hidden;
}

.one-design-project {
    width: 354px;
    height: auto;
    position: relative;
    margin-right: 25px;
    /* margin-bottom is important because scrollbar
       will appear after it in viewports smaller than
       500px */
    margin-bottom: 30px;
    cursor: pointer;
}

.one-design-project a {
    text-decoration: none;
}

/* https://css-tricks.com/almanac/selectors/l/last-child/ */
/* Using the > changes the description to: "Find any divs
   that are the direct descendents of section divs".
   https://stackoverflow.com/a/2094512/10029397 */
#all-projects > div:last-of-type {
    margin-right: 0;
}

.title-and-description-container {
    margin: 0 5px;
}

/*.one-design-project changes on hover*/
.img-hover-zoom {
    height: 354px;
    overflow: hidden;
    border-radius: var(--border-radius);
}

.img-hover-zoom:hover img {
    transform: scale(1.05);
}

.one-design-project:hover .img-hover-zoom img {
    transform: scale(1.05);
}

/*------*/


.design-project-img {
    width: 354px;
    height: 354px;
    border-radius: var(--border-radius);
    overflow: hidden;
    transition: transform .5s;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.project-description-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.light-gray-text {
    color: var(--color-gray-font);
    line-height: 1.3;
    padding: 0 0 6px 0;
}

.icons {
    display: flex;
    flex-direction: row;
}

/*Select any img element that is a child of any
  element with a class name of “icons */
.icons img {
    margin-left: 7px;
    width: 34px;
    height: 34px;
    display: flex;
}

button:focus {
    outline: 0;
}

/* one-design-project 16px margin left and
   right ends at 1280px. */
@media only screen and (max-width: 1280px) {
    #design-projects-container {
        padding: 0;
    }

    #design-projects-container h2 {
        margin-left: var(--default-page-padding);
    }

    #all-projects > div:first-of-type {
        margin-left: var(--default-page-padding);
    }

    #all-projects > div:last-of-type {
        margin-right: var(--default-page-padding);
    }

    .empty-div {
        border-left: 1px solid white;
        width: var(--default-page-padding);
        height: 439px;
    }
}

@media only screen and (min-width: 900px) {
    .previous-button {
        left: 50px !important;
    }

    .next-button {
        right: 50px !important;
    }
}

/* one-design-project 16px margin left and
   right ends at 600px */
@media only screen and (max-width: 600px) {
    #design-projects-container {
        padding: 0;
    }

    #design-projects-container h2 {
        margin-left: var(--small-page-padding);
    }

    #all-projects > div:first-of-type {
        margin-left: var(--small-page-padding);
    }

    #all-projects > div:last-of-type {
        margin-right: var(--small-page-padding);
    }

    .empty-div {
        border-left: 1px solid white;
        width: var(--small-page-padding);
        height: 439px;
    }
}

@media only screen and (min-width: 501px) {
    #all-projects {
        overflow-x: hidden;
    }

    .previous-button {
        position: absolute;
        z-index: 1;
        bottom: 265px;
        left: 25px;
        height: var(--next-previous-button-size);
        width: var(--next-previous-button-size);
        border-radius: 100px;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 4px;
        opacity: 0;
        box-shadow: none;
        background-color: var(--color-carousel-button-bg);
        transition: box-shadow .1s ease-in-out, background-color .1s ease-in-out;
    }

    .previous-button:hover {
        -webkit-box-shadow: var(--button-box-shadow);
        box-shadow: var(--button-box-shadow);
        background-color: var(--color-carousel-button-bg-hover);
        transition: box-shadow .1s ease-in-out, background-color .1s ease-in-out;
    }

    .next-button {
        position: absolute;
        z-index: 1;
        bottom: 265px;
        right: 25px;
        height: var(--next-previous-button-size);
        width: var(--next-previous-button-size);
        border-radius: 100px;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 9px;
        opacity: 0;
        background-color: var(--color-carousel-button-bg);
        box-shadow: none;
        transition: box-shadow .1s ease-in-out, background-color .1s ease-in-out;
    }

    .next-button:hover {
        -webkit-box-shadow: var(--button-box-shadow);
        box-shadow: var(--button-box-shadow);
        background-color: var(--color-carousel-button-bg-hover);
        transition: box-shadow .1s ease-in-out, background-color .1s ease-in-out;
    }
}

@media only screen and (max-width: 500px) {
    #all-projects {
        overflow-x: scroll;
    }

    .previous-button {
        display: none;
    }

    .next-button {
        display: none;
    }
}