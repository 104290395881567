/*Read more on different font formats: https://www.w3schools.com/css/css3_fonts.asp#:~:text=OpenType%20Fonts%20(OTF),on%20the%20major%20computer%20platforms.*/
@font-face {
  font-family: "Gilroy-Bold";
  src: url("Gilroy-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy-SemiBold";
  src: url("Gilroy-SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy-Medium";
  src: url("Gilroy-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Jetbrains Mono Regular";
  src: url("JetBrainsMono-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "JetBrains Mono Extra Bold";
  src: url("JetBrainsMono-ExtraBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

:root {
  /*Fonts*/
  --default-font: "Gilroy-SemiBold", "Lexend Deca", sans-serif;
  --bold-font: "Gilroy-Bold", "Lexend Deca", sans-serif;
  --medium-font: "Gilroy-Medium", "Lexend Deca", sans-serif;
  --default-font-size: 1em;
  --secondary-font: "Jetbrains Mono Regular", "Roboto Mono", monospace;
  --secondary-font-bold: "JetBrains Mono Extra Bold", "Roboto Mono", monospace;

  /*Other*/
  --hover-transition: 0.1s;
  --dropdown-opacity-animation-delay: 170;
  --max-app-width: 1435px;
  --letter-spacing: 1px;
  --default-page-padding: 31px;
  --small-page-padding: 16px;
  --distance-between-home-page-components: 35px;
  --distance-between-project-page-components: 42px;
  --border-radius: 15px;
  --button-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
  --dropdown-box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.4);
  --dropdown-triangle-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3);
  --color-triangle-bg: #ffffff;
  --color-white: #ffffff;
  --color-gray-accent: rgba(143, 149, 150, 0.3);
  --color-gray-lighter: rgba(23, 27, 34, 0.9); /* Default lighter and darkest are the same colors. */
  --color-gray-darkest: rgba(23, 27, 34, 0.9);
  --color-dark-blue-800: rgba(11, 14, 18, 0.7);
  --color-gray-font: #717171;
  --color-gray-hover-bckround: #f7f7f7; 
  --color-dropdown-background: #ffffff;
  --color-dropdown-selected: #efefef;
  --color-dropdown-selected-text: rgba(23, 27, 34, 0.9);
  --color-yellow: rgba(238, 204, 17, 0.9);
  --color-yellow-lighter: #fcdb26;
  --color-yellow-faded: #726419;
  --color-green: #067b11;
  --color-try-app-button-text: #303030;

  --color-carousel-button-bg-hover: #ffffff;
  --color-carousel-button-bg: #efefef;

  --color-modal-button-bg-hover: #eecc11;
  --color-modal-button-bg: #efefef;

  --color-triangle-border: #dfdfdf;
  --color-highlight-text: #303030;
}

/*----- Reset all the CSS styles -----*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: var(--default-font-size);
  vertical-align: baseline;
  box-sizing: border-box;
  font-family: var(--default-font);
  color: var(--color-gray-darkest);
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*-----  -----*/

body {
  max-width: var(--max-app-width);
  margin: 0 auto;
  font-size: var(--default-font-size);
  background-color: var(--color-white);
}

html {
  overflow-y: scroll;
}

h1 {
  font-size: 3em;
  letter-spacing: var(--letter-spacing);
  font-family: var(--medium-font);
}

h2 {
  font-size: 1.5em;
  padding: 0 0 18px 0;
  letter-spacing: var(--letter-spacing);
  font-family: var(--medium-font);
}

h3 {
  font-size: 4em;
  letter-spacing: var(--letter-spacing);
  font-family: var(--medium-font);
}

h4 {
  font-size: 1.2em;
  padding: 15px 0 6px 1px;
  letter-spacing: var(--letter-spacing);
  font-family: var(--medium-font);
}

h5 {
  font-size: 1.41em;
  letter-spacing: var(--letter-spacing);
  font-family: var(--medium-font);
}

p {
  font-family: var(--secondary-font);
  font-size: 0.82rem;
  line-height: 1.4;
}

/*Text selection color*/
::selection {
  background: var(--color-yellow);
  color: var(--color-highlight-text);
}
::-moz-selection {
  background: var(--color-yellow);
}

/*Scrollbar*/
::-webkit-scrollbar {
  width: 13px;
  height: 13px;
}

::-webkit-scrollbar-track {
  border: 1px solid var(--color-gray-hover-bckround);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /*border: 1px solid #C6C6C6;*/
  background: var(--color-gray-accent);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--color-gray-font);
}

code {
  font-family: var(--secondary-font);
  color: #1B1C1E;
  background-color: rgba(228, 230, 232, 0.757);
  padding: 2px 8px;
  border-radius: 8px;
}